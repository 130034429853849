<template>
  <v-card flat :style="`height: ${ windowSize.y }px; position: 'relative'`" class="pa-1 recount-view" v-resize="onResizeHandler">
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="12" class="pa-0 pl-3 font-weight-bold">
          {{ $t('message.ccsheet.warehouse') }} {{ $t('message.ccsheet.reCount') }}: {{ sheetModel.warehousename }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="highlight pt-0 pb-0 pl-1 mt-2">
      <v-row>
        <v-col cols="4" class="pa-0 pl-3 font-weight-bold">{{ $t('message.whsDashboard.product') }}:</v-col>
        <v-col cols="3" class="pa-0 pr-2 text-center font-weight-bold">{{ $t('message.order.location') }}</v-col>
        <v-col cols="2" class="pa-0 text-right font-weight-bold">{{ $t('message.ccsheet.stock') }}</v-col>
        <v-col cols="3" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.ccsheet.counted') }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-divider class="pa-0"></v-divider>
        </v-col>
      </v-row>
      <!-- Load list of products -->
      <v-form ref="ccsheetItemsCount" v-model="valid">
        <template v-for="(group, key) in listOfProducts">
          <v-row v-for="(product, index) in group" :key="`${key}_${index}_productitem`">
            <v-col cols="4" class="pa-0 pl-3 text-truncate mx-auto-full" :title="product.productnumber">
              <span v-if="index === 0">{{ product.productnumber }}</span>
              <br />
              <span v-if="index === (group.length - 1)">{{ product.productname }}</span>
            </v-col>
            <v-col cols="3" class="pa-0 text-center text-truncate mx-auto-full" :title="product.locationname">{{ product.locationname }}</v-col>
            <v-col cols="2" class="pa-0 text-right text-truncate mx-auto-full" :title="product.onstock">
              {{ product.onstock | absoluteNumber }}
            </v-col>
            <v-col cols="3" class="pa-0 pb-1 pl-3 text-right text-truncate">
              <v-text-field ref="countQty" dense hide-details outlined autocomplete="off" v-model="product.counted"
              :rules="$_zeroValidation"
              :placeholder="$t('message.product.qty')"></v-text-field>
            </v-col>
          </v-row>
        </template>
      </v-form>
    </v-card-text>
     <v-fab-transition>
        <v-btn bottom color="primary" id="scroll__top" class="mt-2 complete_count_btn" tabindex="-1" fixed :disabled="!valid" :loading="saveLoading" @click="completeRecounting">
      {{ $t('message.ccsheet.completeRecount') }}
    </v-btn>
    </v-fab-transition>
  </v-card>
</template>
<script>
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      listOfProducts: [],
      windowSize: {
        x: 0,
        y: 0
      },
      sheetId: 0,
      sheetModel: {},
      saveLoading: false,
      valid: true,
      reInit: 0
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  mounted () {
    this.sheetId = this.$route.params.sheet_id
    this.getCountedProducts()
  },
  methods: {
    getCountedProducts () {
      this.loading = true
      const hasEdit = this.$route.params.has_edit
      hostAppApi.get(`${this.getHostRefApi}ccsheets/get_ccsheet/${this.sheetId}?user_id=${this.userId}&get_for_counting=${hasEdit}`)
        .then((response) => {
          if (response.data) {
            const { ccsheet } = response.data
            if (hasEdit === 'true') window.history.replaceState(null, null, `cycle_count/recount/${this.sheetId}/false`)
            this.sheetModel = ccsheet
            this.sheetId = ccsheet.id
            const getStocks = ccsheet.stocks || []
            const recountProducts = getStocks.filter(x => x.onstock !== x.counted)
            recountProducts.map(x => {
              if (x.counted === 0) x.counted = '0'
            })
            const groupByProduct = this.$formatter.groupBy(recountProducts, 'productid')
            this.listOfProducts = groupByProduct
            setTimeout(() => {
              this.focusField('countQty')
              this.$refs.ccsheetItemsCount.validate()
            }, 100)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    completeRecounting () {
      if (this.$refs.ccsheetItemsCount.validate()) {
        this.saveLoading = true
        const allProducts = this.$formatter.cloneVariable(this.listOfProducts)
        const getAllProducts = []
        Object.keys(allProducts).forEach(x => {
          getAllProducts.push(...allProducts[x])
        })
        const resultProducts = []
        for (let i = 0; i < this.sheetModel.stocks.length; i++) {
          const getProduct = getAllProducts.find(x => x.id === this.sheetModel.stocks[i].id)
          if (!getProduct) {
            resultProducts.push(this.sheetModel.stocks[i])
          }
        }
        const modelProducts = [...getAllProducts, ...resultProducts]
        const model = { sheetid: this.sheetId, stocks: [] }
        const transformData = { number: { list: ['onstock', 'counted'], format: 'replaceCommaWithDot' } }
        model.stocks = this.$formatter.formatListModel(modelProducts, transformData)
        hostAppApi.post(`${this.getHostRefApi}ccsheets/complete_ccsheet?user_id=${this.userId}&recount=1`, model)
          .then((response) => {
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.updatedSuccess' })
            this.$router.push('/cycle_count')
          })
          .finally(() => {
            this.saveLoading = false
          })
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.validationIssue') })
      }
    },
    onResizeHandler () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight - 40 }
    },
    focusField (value) {
      requestAnimationFrame(() => {
        if (this.$refs[value]) {
          this.$refs[value][0].focus()
        }
      })
    }
  }
}
</script>
<style>
  .recount-view .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
    padding: 0px 8px !important;
  }
  .mx-auto-full {
    margin: auto;
  }
  .complete_count_btn {
    bottom: 4rem!important;
  }
</style>
